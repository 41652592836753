import UIkit from 'uikit'
import notify from './notify.js'
import cartModel from './cart-model.js'
import FormatMoney from '../libs/format-money.js'
import CartCount from './cart-count.js'

class CartAddClass {

  show(item) {
    console.log('CartAddClass',item);
    
    this.item = item;
    this.crat = parseFloat(item.CRAT)
    if (this.$modal === undefined) {
      this.init();
    }
    $("#add-title").text(item.NAME);
    $("#add-price").text(FormatMoney(item.PRICE));
    $("#add-delivery").text(item.DELIVERY_INFO);
    $("#add-price-edizm").text('₽'+((this.crat !== 1)?'/'+item.NOM_EDIZM:''))
    $("#add-count-edizm").text((this.crat !== 1)?'('+item.NOM_EDIZM+')':'')
    this.count.setItem(item)

    if (this.crat !== 1) {
      $("#add-count2-cont").show()
      $("#add-count2").val('')
    } else {
      $("#add-count2-cont").hide()
    }

    // UIkit.toggle('#addToCart', {
    //   animation: 'uk-animation-fade'
    // }).show();
    this.running = false;
    this.loading(false);
    this.addDialog.show();
  }
  
  hide() {
    // start slide bottom animations
    this.$adToCartDialog.addClass('close');
    setTimeout(()=>{
      // close modal
      this.addDialog.hide();
      this.$adToCartDialog.removeClass('close');
      this.loading(false);
    }, 600);    
  }
  
  //блокировка компонентов
  loading(lock) {
      this.$add.attr('disabled', lock);
      this.$cancel.attr('disabled', lock);
      this.$text.attr('hidden', lock);
      this.$process.attr('hidden', !lock);
      this.count.setDisabled(lock)
  }
  
  addToCart() {
    this.running = true;
    this.loading(true);
    cartModel.add(this.item.NOM_ID,this.count.getCountPack());
  }
  
  addComplete() {
    if (!this.running) return;
    notify('Товар добавлен в корзину', 'success');
    this.hide();
    this.running = false;
  }

  count2Inc(plus) {
    let value = parseInt($("#add-count2").val())
    console.log('count2Inc',value)
    if (isNaN(value)) {
      value = 1
    } else if (value < 1) {
      value = 1
    } else if (plus) {
      value++
    } else if (!plus && value > 1) {
      value--
    }
    $("#add-count2-minus").prop("disabled",value <= 1)
    $("#add-count2").val(value)
    this.count.setCountPack(value)
    this.calcSumm()
  }

  calcSumm() {    
    let newValue = this.count.value
    let summ = Math.round(this.item.PRICE * newValue * 100)/100
    this.$summ.text(FormatMoney(summ))
  }

  init() {
    this.running = false;
    
    this.$modal = $("#addToCart");
    this.addDialog = UIkit.modal(this.$modal[0], {
      bgClose: true,
      escClose: true
    });
    
    this.$process = $('.js-process-add-to-cart');
    this.$text = $('.js-text-add-to-cart');
    this.$add = $('.js-confirm-add-to-cart');
    this.$cancel = $('.js-cancel-add-to-cart');
    this.$summ = $('#add-summ');

    this.count = new CartCount({
      input: $('#add-count'),
      minus: $('#add-count-minus'),
      plus: $('#add-count-plus')
    })
    this.count.onChange = (packCount,newValue)=>{
      $("#add-count2").val('')
      this.calcSumm()
    }
    this.calcSumm()

    $("#add-count2-plus").on('click',(ev)=> { ev.preventDefault(); this.count2Inc(true) } )
    $("#add-count2-minus").on('click',(ev)=> { ev.preventDefault(); this.count2Inc(false) } )

    this.$adToCartDialog = $('.shop-item-counter');

    this.$add.on('click',()=>{
      this.addToCart();
    });    
    cartModel.onChanged.push(()=>{
      this.addComplete();
    });
  }
}

export default new CartAddClass();