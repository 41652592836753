let $I = {

  showError : function(errorType,errorCode,errorStr) {
    if (errorType == "user") {
      alert( errorStr );
    } else if (errorType == "auth") {
      location.href = '/';
    } else {
      alert( "Произошла внутренняя ошибка,\nпопробуйте выполнить операцию позже..." );
    }
  },

  json : function (url,params,callback,cbError) {
    $.post(url,params,function(jsonData) {
      console.log('imt json '+url,params,jsonData);
      if (typeof(jsonData.error) == "undefined") {
        if (callback) {
          callback(jsonData);
        }
      } else {
        if (typeof(cbError) != "undefined") {
          cbError(jsonData.error,jsonData.errorCode,jsonData.errorStr);
        } else {
          $I.showError(jsonData.error,jsonData.errorCode,jsonData.errorStr);
        }
      }
    },'json').fail(function(e) {
      console.error('jsonAsync',url,params,e);
      if (typeof(cbError) != "undefined") {
        cbError('js');
      } else {
        $I.showError('js');
      }
    });
  },
  
  fetch : async function(url,params={},options={}) {
    options = {
      signal : null,
      throwErrors : false,
      cors : false,
      type: 'text',
      ...options
    }
    //console.log('fetch',url,params,options)
    try {
      let response = null;
      try {
        let formData = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
        
        let fetchOptions = {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                //'Content-Type': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            },
            body: formData, // тип данных в body должен соответвовать значению заголовка "Content-Type"
        };
        if (options.cors) {
          fetchOptions.mode = 'cors';
          fetchOptions.credentials = 'include';
        }
        if (options.signal) {
          fetchOptions.signal = options.signal
        }
        response = await fetch(url,fetchOptions);
      } catch (e) {
        if (e.name === "AbortError") {
          throw {type: 'abort', text: e};
        }
        throw {type: 'js', text: 'Fetch: '+e};
      }      
      if (response.status != 200) {
        throw {type: 'js', text: 'Http return '+response.status};
      }

      if (options.type === 'json') {
        let result = await response.json();
        console.log('imt json '+url,params,result);
        if (result.error !== undefined) {
          throw {type: result.error, text:result.errorStr, code:result.errorCode};
        }
        return result;

      } else {
        return await response.text()
      }

    } catch (e) {
      console.error('fetch',url,params,e);
      if (options.throwErrors) {
        throw e;
      } else {
        $I.showError(e.type,e.code,e.text)
        return null
      }      
    }
  },

  jsonAsync : async function (url,params={},options=false) {
    if (typeof options === "boolean") {
      //обратная совместимость
      options = { throwErrors : options }
    }
    options = {
      ...options,
      type: 'json'
    }
    return $I.fetch(url,params,options)
  },
  
  escapeHtml : function(unsafe) {
    return unsafe
         .replace(/&/g, "&amp;")
         .replace(/</g, "&lt;")
         .replace(/>/g, "&gt;")
         .replace(/"/g, "&quot;")
         .replace(/'/g, "&#039;");
  },

  redirectPost : function(location, args) {
    var form = '';
    $.each( args, function( key, value ) {
        form += '<input type="hidden" name="'+key+'" value="'+$I.escapeHtml(''+value)+'">';
    });
    $('<form action="'+location+'" method="POST">'+form+'</form>').appendTo("body").submit();
  },
  
  metrikaReach : function(goal_name) {
    for (var i in window) {
      if (/^yaCounter\d+/.test(i)) {
        window[i].reachGoal(goal_name);
      }
    }
  }  

};

export default $I;