import notify from './notify.js'

class FastOrderModal {
  
  setLoading(loading) {
    $("#fast-order-loading").prop('hidden',!loading);
    $("#fast-order-submit").prop('hidden',loading);
  }
  
  async submit() {
    let phone = $("#fast-order-phone").val();
    if (phone == '') {
      notify('Введите телефон');
      return;
    }
    
    try {
      this.setLoading(true);
      let result = await $I.jsonAsync('/order/fast_order/',{
        "phone" : phone
      },true);
      location.href = '/order/complete/'+result.order+'/';
      
    } catch(e) {
      this.setLoading(false);
      $I.showError(e.type,e.code,e.text);
    }
    
  }

  init() {
    this.setLoading(false);
    //$("#fast-order-form").submit((ev)=>{
    $("#fast-order-submit").click((ev)=>{
      ev.preventDefault();
      this.submit();
    });
  }
  
  constructor() {}
}

export default new FastOrderModal();