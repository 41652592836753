import cartModel from './cart-model.js'
import FormatMoney from '../libs/format-money.js'
import CartCount from './cart-count.js'

class CartMiniClass {

  setLoading(loading) {
    $("#cart-mini-summary").children('div').prop('hidden',loading);
    $("#cart-mini-commit").prop('hidden',loading);
    $("#cart-mini-loading").prop('hidden',!loading);
    if (!loading) {
      this.lockCount(loading)
    }
  }

  lockCount(loading) {
    this.$items.find('.js-item-count').prop('disabled',loading)
    this.$items.find('.cart-stepper button').toggleClass('is-disabled',loading).prop('disabled',loading)
  }

  itemRemove(item,$item) {
    console.log('itemRemove',item.NOM_ID);
    $item.remove();
    cartModel.set( item.NOM_ID , 0 , false );
  }

  createItem(item) {
    let $item = $(this.tplItem({item}));
    $item.find('[uk-close]').on('click',()=>{
      this.itemRemove(item,$item);
    })
    let $summ = $item.find('.mini-cart--item-sum')

    let cartCount = new CartCount({
      input: $item.find('.cart-stepper--input'),
      minus: $item.find('.cart-stepper--decrease'),
      plus: $item.find('.cart-stepper--increase')
    })
    cartCount.onChange = (packCount,newValue)=>{
      console.log('itemChange',item.NOM_ID,packCount,newValue);
      let summ = Math.round(item.PRICE * newValue * 100)/100
      $summ.text(FormatMoney(summ))
      cartModel.set( item.NOM_ID , packCount , true );
    }
    cartCount.setItem(item, item.COUNT)
    let summ = Math.round(item.PRICE * cartCount.value * 100)/100
    $summ.text(FormatMoney(summ))

    return $item;
  }

  refresh() {
    let data = cartModel.data;
    console.log('refresh',data);
    if (data === null) {
      return;
    }
    
    if (data.count == 0) {
      this.$countBadge.prop('hidden',true);
      this.$full.prop('hidden',true);
      this.$empty.prop('hidden',false);
      $("#cart-button").hide()
      return;
    }
    
    $("#cart-button").show()
    this.$countBadge.prop('hidden',false);
    this.$countBadge.text(data.count);
    this.$full.prop('hidden',false);
    this.$empty.prop('hidden',true);
    
    this.$count.text(data.count);
    this.$total.text(FormatMoney(data.discount_total));
    
    this.$items.empty();
    console.log('refresh mini cart',data.items)
    data.items.forEach((item)=>{
      let $item = this.createItem(item);
      $item.appendTo(this.$items);
      //console.log('createItem',$item.find('[uk-close]'));
    });
    
    this.setLoading(false)    
  }

  init() {
    this.$countBadge = $('.car-cart-badge');
    this.$empty = $('#cart-mini-empty');
    this.$full = $('#cart-mini-full');
    this.$items = $('#cart-mini-items');
    this.$total = $('#cart-mini-total');
    this.$count = $('#cart-mini-count');

    cartModel.onBeforeChange.push(()=>{
      this.setLoading(true);
    });
    cartModel.onChanging.push(()=>{
      this.lockCount(true);
    });    
    cartModel.onChanged.push(()=>{
      this.refresh();
    });
   
    this.setLoading(true);
    this.refresh();
  }
  
  constructor() {
    this.tplItem = require('./cart-mini-item.hbs');
  }
  
}

export default new CartMiniClass();