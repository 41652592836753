import notify from './notify.js'

export function registerPush() {
  if (typeof(app) !== "undefined") {
    app.setToken(appserver_token)
  }
  if (window.webkit !== undefined) {
    window.webkit.messageHandlers.setTokenHandler.postMessage(appserver_token)
  }
}

export function unregisterPush() {
  if (typeof(app) !== "undefined") {
    app.deleteToken(appserver_token)
  }
  if (window.webkit !== undefined) {
    window.webkit.messageHandlers.deleteTokenHandler.postMessage(appserver_token)
  }
}