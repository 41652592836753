
export default class {

    //$els = { input: , plus: , minus: }
    constructor($els) {
        this.onChange = (newCountPack,newVal)=>{}
        this.crat = 1
        this.countPack = 1
        this.value = 1 
        this.$input = $els.input
        this.$minus = $els.minus
        this.$plus = $els.plus
        this.$input.on('change',(ev)=>{ this.valueChanged() })
        this.$minus.on('click',(ev)=>{ ev.preventDefault(); this.countInc(false) })
        this.$plus.on('click',(ev)=>{ ev.preventDefault(); this.countInc(true) })
    }

    setItem(item,countPack = 1) {
        this.item = item
        this.crat = parseFloat(item.CRAT)
        this.setCountPack(countPack)
    }

    setDisabled(disabled) {
        this.$input.prop('disabled',disabled)
        this.$plus.prop('disabled',disabled)
        this.$minus.prop('disabled',disabled)
    }

    countInc(plus) {
        let newVal = parseFloat(this.$input.val())
        newVal = newVal + (plus?this.crat:-this.crat)
        console.log('countInc',newVal)
        this.valueChanged(newVal)
    }

    valueChanged(newValue = null) {
        if (newValue === null) {
            newValue = parseFloat(this.$input.val())
        }
        this.setValue(newValue)
        console.log('valueChanged',this.countPack,this.value)
        this.onChange(this.countPack,this.value)
    }

    setValue(newValue) {
        this.value = newValue
        //Округляем, обрабатываем само значение
        if (isNaN(this.value)) this.value = this.crat
        this.value = Math.round(this.value * 10) / 10
        this.$minus.prop('disabled',(this.value <= this.crat))
    
        //Округляем до упаковки
        this.countPack = Math.ceil( Math.round( this.value / this.crat * 100) / 100 )
        if (this.countPack < 1) this.countPack = 1
        console.log('newCountPack',this.countPack)
        this.value = Math.round(this.countPack * this.crat * 10) / 10
    
        this.$input.val(this.value)
    }

    setCountPack(countPack) {
        this.countPack = parseInt(countPack)
        this.setValue(this.countPack * this.crat)
    }

    getCountPack() {
        return this.countPack
    }
    
}