import cartAdd from './cart-add.js'
import cartModel from './cart-model.js'
import notify from './notify.js'

export default class {
  
  setInCart(incart) {
    this.$btnToCart.toggleClass('uk-button-danger',incart);
    this.$btnToCart.toggleClass('uk-button-secondary',!incart);
    this.$item.toggleClass('in-cart',incart);
  }
  
  cartRefresh() {
    let newItem = cartModel.itemById(this.data.NOM_ID);
    this.setInCart((newItem != null) && (newItem.COUNT_PACK > 0));
  }
  
  favRefresh() {
    this.$item.find('.shop-item-fav').toggleClass('in-fav',this.inFavorites);
    let favText = this.inFavorites?'Убрать товар из избранного':'Добавить товар в избранное'
    this.$item.find('.shop-item-fav').attr('uk-tooltip',"title: "+favText+"; pos: bottom-right;");                
  }
  
  async addToFavorite() {
    this.inFavorites = !this.inFavorites;
    this.favRefresh();
    if (!this.inFavorites) {
      await $I.jsonAsync("/favorites/del/",{"nom_id" : this.data.NOM_ID});
      notify('Товар убран из избранного', 'success');
    } else {
      await $I.jsonAsync("/favorites/add/",{"nom_id" : this.data.NOM_ID});
      notify('Товар добавлен в избранное', 'success');
    }
  }
  
  constructor($item) {
    this.$item = $item;
    this.data = JSON.parse(this.$item.attr('data'));
    this.$btnToCart = this.$item.find('.js-add-to-cart');
    //console.log('catItem',this.data);
    
    this.setInCart();
    
    this.$btnToCart.click(()=>{
      if (!this.$item.hasClass('in-cart')) {
        cartAdd.show(this.data);
      } else {
        cartModel.set( this.data.NOM_ID , 0 , false );
        notify('Товар убран из корзины', 'success');
      }      
    })
    
    cartModel.onChanged.push(()=>{
      this.cartRefresh();
    });    
    this.cartRefresh();
    
    if (registred) {
      this.inFavorites = (this.data.COUNT_IN_FAVORITE == '1');
      this.$item.find('.shop-item-fav').prop('hidden',false);
      this.$item.find('.shop-item-fav').click(()=>{ this.addToFavorite(); });
      this.favRefresh();
    }
  }
  
}