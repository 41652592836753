//catalogTreeGenerator
export default class {
  
  renderTreeChildren($list, parent) {
    this.catalogTreePanels[parent.id] = $list;
    for (let i in parent.children) {
      let itm = parent.children[i];
      let $itm = $('<li></li>');
      let itmLink = itm.link;
      if (itm.id == this.pageCategoryId) {
        this.curTreePanelId = parent.id;
        $itm.append('<a href="'+itm.link+'" class="uk-text-bold">'+itm.title+'</a>');
      } else {
        $itm.append('<a href="'+itm.link+'">'+itm.title+'</a>');
      }
      //$itm.append('<a href="'+itm.link+'"><span class="uk-margin-small-right" uk-icon="cog"></span>'+itm.title+'</a>');
      if (itm.children.length) {
        let $children = $('<ul></ul>');
        this.renderTreeChildren($children,itm);
        $children.appendTo($itm);
      }
      $itm.appendTo($list);
    }  
  }
  
  render($container,tree) {
    let $list = $('<ul></ul>');
    $list.appendTo($container);
    for (let i in tree) {
      let itm = tree[i];
      let $itm = $('<li></li>');
      $itm.append('<a href="'+itm.link+'"></span>'+itm.title+'</a>');
      if (itm.children.length) {
        let $children = $('<ul></ul>');
        this.renderTreeChildren($children,itm);
        $children.appendTo($itm);
      }
      $itm.appendTo($list);
    }
    console.log("catalogTreePanels",this.catalogTreePanels);    
  }
  
  constructor() {
    this.catalogTreePanels = {};    
    this.curTreePanelId = null;
    this.pageCategoryId = null;
    if (typeof(curCategoryId) != "undefined") {
      this.pageCategoryId = curCategoryId;
    }    
  }
  
}