var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"uk-width-auto uk-text-muted uk-text-small\">\n            +"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"POINTS") : stack1), depth0))
    + " бал.\n          </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!--товар в корзине-->\n<div class=\"car-mini-cart--item\">\n  <div class=\"uk-grid uk-grid-small uk-flex uk-flex-middle\" uk-grid>\n    <!--Фото (квадрат 75х75px)-->\n    <a href=\"/catalog/item/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"ALIAS_NAME") : stack1), depth0))
    + "/\" class=\"uk-width-auto car-mini-cart-item__image \">\n      <canvas width=\"75\" height=\"75\" class=\"uk-background-contain uk-border-rounded\" data-src=\"/carabi/image/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"PHOTO_MAIN") : stack1), depth0))
    + "/75x75/fit/\" uk-img></canvas>\n    </a>\n    <div class=\"uk-width-expand\">\n      <!--Название товара-->\n      <div class=\"car-mini-cart-item__title\">\n        <div class=\"uk-grid-small\" uk-grid>\n          <div class=\"uk-width-expand uk-text-truncate\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"NAME") : stack1), depth0))
    + "</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"POINTS") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":10},"end":{"line":17,"column":19}}})) != null ? stack1 : "")
    + "          <div class=\"uk-width-auto\">\n            <button type=\"button\" uk-close></button>\n          </div>\n        </div>\n      </div>\n      <div class=\"uk-grid uk-grid-small uk-flex uk-flex-middle\" uk-grid>\n        <!--Счетчик товаров-->\n        <div class=\"uk-width-expand@s uk-width-1-1\">\n          <div class=\"cart-stepper uk-flex uk-flex-middle\">\n            <button class=\"uk-icon-link cart-stepper--decrease\" uk-icon=\"icon: minus; ratio: 0.7\"></button>\n            <input type=\"text\" class=\"js-item-count uk-input uk-form-small cart-stepper--input uk-border-pill\">\n            <button class=\"uk-icon-link cart-stepper--increase\" uk-icon=\"icon: plus; ratio: 0.7\"></button>\n          </div>\n        </div>\n        <!--Цена-->\n        <div class=\"uk-width-auto@s uk-width-1-1\">\n          <p class=\"mini-cart--item-price\">\n            <span class=\"uk-text-muted uk-margin-left\">по</span>\n            <span class=\"js-auto-numeric uk-text-bold\">"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"item") : depth0)) != null ? lookupProperty(stack1,"DISCOUNT_PRICE") : stack1), depth0))
    + "</span>\n            <span class=\"uk-text-muted\">₽</span>\n            &nbsp;\n            <span class=\"mini-cart--item-sum js-auto-numeric uk-text-bold\"></span>\n            <span class=\"uk-text-muted\">₽</span>\n          </p>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});