import listTimeout from '../libs/list-timeout.js'

//Разработка не окончена, сейчас задачу этого класса выполняет cart-mini.js
class CartModelClass {
  
  add(nom_id,count) {
    console.log('addToCart',nom_id,count);
    if (count > 100) count = 100
    this.triggerBeforeChange();
    $I.json("/cart/add/",{
        "nom_id" : nom_id,
        "count" : count
    },(jsonResult)=>{
        this.reload();
    });
  }

  set(nom_id,count,delayed = fasle) {
    let oldCount = this.itemById(nom_id).COUNT_PACK
    console.log('set',oldCount,count,oldCount == count)
    if (oldCount == count) {
      return
    }
    this.triggerBeforeChange();
    if (count > 100) count = 100
    this.timeout.change( nom_id, count );
    if (!delayed) {
      this.timeout.start();
    }
  }

  itemById(nom_id) {
    for (let i in this.data.items) {
      let itm = this.data.items[i];
      if (nom_id == itm.NOM_ID) {
        return itm;
      }
    }
    return null;
  }

  reload() {
    this.triggerChanging()
    let ch = this.timeout.changed()
    if ((this.timeout.running) || (Object.entries(ch).length)) {
      //Не обновляемся пока меняются данные
      return;
    }
    //this.triggerBeforeChange();
    localStorage.setItem('cartReloading','1');
    $I.json("/cart/mini/",{},(jsonResult)=>{
      this.data = jsonResult;
      this.data.timestamp = moment().format();
      
      this.setTimeoutValues(this.data.items);
      this.triggerChanged();
      
      localStorage.setItem('cartReloading','0');
      localStorage.setItem('cart',JSON.stringify(this.data));
    });
  }

  clear() {
    localStorage.removeItem('cartReloading');
    localStorage.removeItem('cart');
  }

  async timeoutCommit(values) {
    let calls = [];
    for (let id in values) {
      calls.push( new Promise((resolve, reject) => {
        $I.json("/cart/set_count/",{
          "nom_id" : id,
          "count" : values[id],
        },(jsonResult)=>{ resolve(); });
      }));
    }
    await Promise.all(calls); 
  }
  
  setTimeoutValues(items) {
    let values = {};
    items.forEach((item)=>{
      values[item.NOM_ID] = item.COUNT_PACK;
    });
    this.timeout.setItems(values);    
  }

  loadStorage(newData) {
    if (newData === undefined) {
      newData = localStorage.getItem('cart');
      newData = JSON.parse(newData);
    }    
    if (newData === null) {
      return;
    }
    //console.log('loadStorage',newData);
    this.data = newData;
    this.setTimeoutValues(this.data.items);
    
    if (!this.checkTimestamp()) {
      this.triggerChanged();
    }
  }
  
  triggerBeforeChange() {
    for (let i in this.onBeforeChange) {
      this.onBeforeChange[i](this.data);
    }
  }

  triggerChanging() {
    for (let i in this.onChanging) {
      this.onChanging[i](this.data);
    }
  }

  triggerChanged() {
    for (let i in this.onChanged) {
      this.onChanged[i](this.data);
    }
  }

  checkTimestamp() {
    if (this.data === null) return;
    let timestamp = moment(this.data.timestamp);
    let oldTimestamp = moment().subtract(1,"minutes");//.subtract(10,"seconds");
    //console.log('checkTimestamp',timestamp.format(),oldTimestamp.format());
    if (timestamp.isBefore(oldTimestamp)) {
      //console.log('checkTimestamp isBefore');
      if (localStorage.getItem('cartReloading') != '1') {
        console.log('checkTimestamp reload');
        this.reload();
        return true;
      }
    }
    return false;
  }
  
  constructor() {
    //Корзина изменяется (до перезагрузки)
    this.onBeforeChange = [];
    //Корзина изменяется (старт перезагрузки)
    this.onChanging  = []
    //Корзина изменилась
    this.onChanged = [];
   
    this.data = null;
   
    this.timeout = new listTimeout();
    this.timeout.onCommit = this.timeoutCommit;
    this.timeout.onFinish = ()=>{ this.reload(); };

    this.loadStorage();
    if (this.data === null) {
      this.reload();
    }
   
    window.addEventListener('storage', (event)=>{
      if (event.key == 'cart') {
        this.loadStorage(event.newValue);
      }
    });
    
    setInterval(()=>{
       this.checkTimestamp();
    },1000);
  }
  
}

export default new CartModelClass();