import UIkit from 'uikit'
import notify from './notify.js'
import {registerPush} from './register-push.js'
import Inputmask from "inputmask"

class AuthModalClass {

  getPhone() {
    let phone = $("#auth_phone").val()
    phone = phone.replace(/\D/g,'')
    return phone
  }

  async submitPhone() {
    this.smsSeconds = 60
    if (!this.smsInterval) {
      this.smsInterval = setInterval(()=>{
        this.smsTick()
      },1000)
    }
    this.smsTick()
    await $I.jsonAsync("/login/send_code/",{
      phone : this.getPhone()
    },true)
    this.phoneSubmitted = true
    setTimeout(()=>{
      $("#auth_code").focus()
    },50)
  }

  smsTick() {
    this.smsSeconds--
    if (this.smsSeconds > 0) {
      $("#auth_resend").text('Выслать через '+this.smsSeconds)
    } else {
      clearInterval(this.smsInterval)
      this.smsInterval = null
      $("#auth_resend").text('Выслать повторно')
    }
  }  

  async submitCode() {
    try {
      let code = $("#auth_code").val()
      await $I.jsonAsync("/login/auth/",{
          "login" : this.getPhone(),
          "password" : code
      },true)
    } catch (e) {
      throw {
        type: "user",
        text: "Неправильный код подтверждения"
      }
    }

    registerPush()
    UIkit.modal('#modal-auth').hide()
    this.onSuccess()
  }

  async submit() {
    $("#auth_error").attr('hidden',true);
    this.process = true
    this.updateStatus()

    try {
      if (!this.phoneSubmitted) {
        await this.submitPhone()
      } else {
        await this.submitCode()
      }
    } catch(e) {
      console.error('auth error',e)
      let errorStr = 'Внутренняя ошибка, попробуйте выполнить позже...';
      if (e.text) {
        errorStr = e.text
      }
      $("#auth_error").text(errorStr).attr('hidden',false);
      //this.button_text.html('Повторить');
    }

    this.process = false
    this.updateStatus()
  }

  updateStatus() {
    $("#auth_code_cont").attr('hidden', !this.phoneSubmitted);
    $("#auth_add_actions").attr('hidden', !this.phoneSubmitted);
    $("#auth_phone").prop('readOnly',this.phoneSubmitted)

    this.spinner.attr('hidden', !this.process);
    this.button_text.html((!this.phoneSubmitted)?
      ((!this.process)?'Отправить':'Отправляется'):
      ((!this.process)?'Подтвердить':'Проверка')
    )
  }

  init() {
    this.onSuccess = ()=>{
      location.reload()
    }

    this.process = false
    this.phoneSubmitted = false;
    this.button_auth = $("#button_auth");
    this.spinner  = this.button_auth.find('.button-action--spinner');
    this.button_text  = this.button_auth.find('.button-action--text');

    // mask
    this.phoneMask = new Inputmask({
      mask: "+7 (999) 999-99-99"
    })
    this.phoneMask.mask($("#auth_phone"));

    //Авторизация
    $('#form_auth').on('submit', (e)=>{
      e.preventDefault()
      this.submit().then(()=>{})
    });

    //Сменить номер
    $('#auth_changenum').on('click', (e)=>{
      e.preventDefault()
      this.phoneSubmitted = false
      this.updateStatus()
      $("#auth_phone").focus()
    });

    //Отправить повторно
    $('#auth_resend').on('click', (e)=>{
      e.preventDefault()
      if (this.smsSeconds > 0) return;
      this.phoneSubmitted = false
      this.submit()
      $("#auth_phone").focus()
    });
    
    this.updateStatus();
  }

  open() {
    UIkit.modal('#modal-auth').show()
  }

  constructor() {
    this.smsInterval = null
    this.smsSeconds = 0
  }

}

export default new AuthModalClass();
