export default class {
  
  //Заменяет элементы, сбрасывает состояние
  //Несохраненные данные будут потеряны!
  setItems(newItems) {
    this.stopTimeout();
    this.items = {};
    for (let i in newItems) {
      this.items[i] = {
        value: newItems[i],
        newValue: newItems[i],
      }
    }
  }
  
  //Возвращает измененные значения
  changed() {
    let result = {};
    for (let i in this.items) {
      let itm = this.items[i];
      if (itm.newValue != itm.value) {
        result[i] = itm.newValue;
      }
    };
    return result;
  }
  
  //Изменяет значение, запускает таймер  
  change(id,val) {
    let values = {};
    values[id] = val;
    this.changeVals(values);
  }
  
  //Изменяет значения, запускает таймер  
  changeVals(values) {
    for (let i in values) {
      if (this.items[i] === undefined) {
        this.items[i] = {
          value: values[i]
        };
      }
      this.items[i].newValue = values[i];
    }
    //Старый таймер неактуален
    this.stopTimeout();
    if (this.running) {
      //Данные изменяются, будет проверка после изменений
      return;
    }
    let ch = this.changed();
    if (Object.entries(ch).length === 0) {
      //Нечего менять
      return;
    }
    this.timeout = setTimeout(()=>{
      this.start();
    },this.msecs);
  }
  
  //Начать прямо сейчас
  start() {
    //Уже запущен
    if (this.running) return;
    //Мы заускаемся, больше нечего ждать
    this.stopTimeout();
    
    let ch = this.changed();
    if (Object.entries(ch).length === 0) {
      //Нечего менять
      return;
    }

    console.log('listTimeout start', ch)
    
    //Теперь новые данные актуальны
    for (let i in ch) {
      this.items[i].value = ch[i];
    }
      
    this.running = true;
    this.onCommit(ch).then(()=>{
    
      this.running = false;
      let ch = this.changed();      
      if (Object.entries(ch).length > 0) {
        //Пока отправляли данные - они изменились
        //Отправим еще раз
        console.log('listTimeout restart', ch);
        this.start();
      } else {
        //Данные успешно изменены
        console.log('listTimeout finish');
        this.onFinish();
      }
    
    });
  }

  constructor() {
    //Событие для отправки новых данных
    this.onCommit = async (items)=>{ };
    //Событие - все данные отправлены
    this.onFinish = ()=>{};
    //Время задержки перед отравкой
    this.msecs = 1000;
    //Отправляются ли данные сейчас?
    this.running = false;
    //PRIVATE
    this.items = {};
    this.timeout = null;
  }
  
  //**PRIVATE**
    
  //Убить таймер
  stopTimeout() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }    
  }
  
  
}