import Semaphore from 'semaphore-async-await';

class catalogTreeModel {
  
  async get() {
    console.log('catalogTreeModel start');
    
    if (this.data !== null) {
      let timestamp = moment(this.data.timestamp);
      let oldTimestamp = moment().subtract(60,"minutes");//.subtract(10,"seconds");
      if (timestamp.isBefore(oldTimestamp)) {
        this.data = null;
      }
    }
    
    await this.lock.acquire();    
      console.log('catalogTreeModel after lock');    
      if (this.data === null) {
        this.data = await $I.jsonAsync('/catalog/category_tree/',{});
        this.data.timestamp = moment().format();
        //localStorage.setItem('catalog',JSON.stringify(this.data));
        console.log('catalogTreeModel reload ',this.data);
      }      
    this.lock.release();
    
    console.log('catalogTreeModel return ',this.data);
    return this.data;
  }
  
  constructor() {
    this.lock = new Semaphore(1);
    //this.data = localStorage.getItem('catalog');
    //this.data = JSON.parse(this.data);
    this.data = null;
  }
  
}

export default new catalogTreeModel();